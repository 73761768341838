<template>
    <div>
        <Service v-if="ShowService" @closeService="ShowService = false" />
        <Privacy v-if="ShowPrivacy" @closePrivacy="ShowPrivacy = false" />
        <LocationBased v-if="ShowLocationBased" @closeLocationBased="ShowLocationBased = false" />
        <div class="mo_space_header">
            <div class="mo_header_box">
                <a>
                    <img src="/media/images/icon_back_24.svg" @click="backspace" />
                </a>
                <h2>회원가입</h2>
                <div></div>
            </div>
        </div>
        <section class="login_main">
            <div class="login_main_box">
                <h1>회원가입</h1>
                <div class="email_input" :class="emailValidate === false && email ? 'email_input_error' : ''">
                    <div class="email_input_box">
                        <input
                            type="text"
                            id="email"
                            placeholder="이메일 ID"
                            v-model="email"
                            @input="emailKeyPress()"
                            @keyup.down="selectAutoComplete(0)"
                            autocomplete="off"
                            aria-autocomplete="none"
                            @keydown.tab="showAutoComplte = false"
                        />
                        <ul
                            id="autocomplete"
                            class="autocomplete"
                            v-if="showAutoComplte"
                            v-click-outside="
                                () => {
                                    showAutoComplte = false;
                                }
                            "
                        >
                            <li
                                class="autocomplete_item"
                                tabindex="0"
                                v-for="(item, index) in getEmailList"
                                :key="item"
                                @keyup.up="selectAutoComplete(index - 1, 'up')"
                                @keyup.down="selectAutoComplete(index === emailList.length - 1 ? emailList.length - 1 : index + 1)"
                                @keyup.enter="settingEmail($event.target)"
                                @click="settingEmail($event.target)"
                            >
                                <span> {{ email.split('@')[0] }}@{{ item }} </span>

                                <div class="border_line" v-if="index !== getEmailList.length - 1"></div>
                            </li>
                        </ul>
                    </div>
                    <button class="email_input_btn" @click="checkEmail()">중복확인</button>
                </div>
                <p v-show="!emailValidate && email" class="error_text">{{ errorText }}</p>
                <input
                    class="password_input1"
                    type="password"
                    id="password"
                    placeholder="비밀번호 (영문, 숫자, 특수문자 포함 8~20자리)"
                    maxlength="20"
                    v-model="password"
                    @keyup="passwordCheck()"
                />
                <div class="in_pw flex_start">
                    <div class="password_vaildate_wrap">
                        <figure class="align_center">
                            <img
                                :src="
                                    passwordValidate.engValidate
                                        ? '/media/img/login/password_validate_check.svg'
                                        : '/media/img/login/password_validate_uncheck.svg'
                                "
                                alt="비활성"
                            />
                        </figure>
                        <span class="validate_text align_center" :class="passwordValidate.engValidate ? 'check' : ''"
                        >영문포함</span
                        >
                    </div>
                    <div class="password_vaildate_wrap ml">
                        <figure class="align_center">
                            <img
                                :src="
                                    passwordValidate.numValidate
                                        ? '/media/img/login/password_validate_check.svg'
                                        : '/media/img/login/password_validate_uncheck.svg'
                                "
                                alt="비활성"
                            />
                        </figure>
                        <span class="validate_text align_center" :class="passwordValidate.numValidate ? 'check' : ''"
                        >숫자포함</span
                        >
                    </div>
                    <div class="password_vaildate_wrap ml">
                        <figure class="align_center">
                            <img
                                :src="
                                    passwordValidate.specialValidate
                                        ? '/media/img/login/password_validate_check.svg'
                                        : '/media/img/login/password_validate_uncheck.svg'
                                "
                                alt="비활성"
                            />
                        </figure>
                        <span class="validate_text align_center" :class="passwordValidate.specialValidate ? 'check' : ''"
                        >특수문자 포함</span
                        >
                    </div>
                    <div class="password_vaildate_wrap ml">
                        <figure class="align_center">
                            <img
                                :src="
                                    passwordValidate.lengthValidate
                                        ? '/media/img/login/password_validate_check.svg'
                                        : '/media/img/login/password_validate_uncheck.svg'
                                "
                                alt="비활성"
                            />
                        </figure>
                        <span class="validate_text align_center" :class="passwordValidate.lengthValidate ? 'check' : ''"
                        >8~20자리</span
                        >
                    </div>
                </div>
                <input
                    class="password_input1"
                    type="password"
                    placeholder="비밀번호 재입력"
                    v-model="password2"
                    @keyup="passEqualCheck()"
                />
                <div class="in_pw">
                    <div class="password_vaildate_wrap">
                        <figure class="align_center">
                            <img
                                :src="
                                    passwordValidate.sameValidate
                                        ? '/media/img/login/password_validate_check.svg'
                                        : '/media/img/login/password_validate_uncheck.svg'
                                "
                                alt="비활성"
                            />
                        </figure>
                        <span class="validate_text align_center" :class="passwordValidate.sameValidate ? 'check' : ''"
                        >비밀번호 일치</span
                        >
                    </div>
                </div>
                <input placeholder="추천인 입력 (선택)" v-model="recommender" />
                <button v-if="!isCheckNice" class="join_certification" @click="clickEvent()">본인 인증</button>
                <button v-if="isCheckNice" class="join_certification check">
                    인증 완료
                    <span class="align_center h_100per"><img src="/media/images/done.png" class="done_img"/></span>
                </button>
                <div style="margin: 0px" class="agree_box">
                    <checkboxAgreeAll v-model="agreeAll" @input="agreeAll1()" />
                    <p style="margin-bottom: 0px">약관 전체 동의</p>
                </div>
                <div class="agree_txt">
                    <ul style="margin-bottom: 0px">
                        <li>
                            <div>
                                <checkboxService v-model="service" @input="agreeService()" />
                                <p style="margin-bottom: 0px">[필수] 사이트 이용약관</p>
                            </div>
                        </li>
                        <li><img src="/media/img/join_agree_icon.png" alt="" @click="ShowService = true" /></li>
                    </ul>
                    <ul style="margin-bottom: 0px">
                        <li>
                            <div>
                                <checkboxLocationBased v-model="locationBased" @input="agreeLocationBased()" />
                                <p style="margin-bottom: 0px">[필수] 위치기반서비스 이용약관</p>
                            </div>
                        </li>
                        <li><img src="/media/img/join_agree_icon.png" alt="" @click="ShowLocationBased = true" /></li>
                    </ul>
                    <ul style="margin-bottom: 0px">
                        <li>
                            <div>
                                <checkboxPrivacy v-model="privacy" @input="agreePrivacy()" />
                                <p style="margin-bottom: 0px">[필수] 개인정보 처리방침</p>
                            </div>
                        </li>
                        <li><img src="/media/img/join_agree_icon.png" alt="" @click="ShowPrivacy = true" /></li>
                    </ul>
                    <ul style="margin-bottom: 0px">
                        <li>
                            <div>
                                <checkboxMaketing v-model="marketing" @input="agreeMarketing()" />
                                <p style="margin-bottom: 0px">[선택] 마케팅 정보 수신</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <button :class="{ disabled: disabledSignUp === true }" @click="[checkAuth2(), signUp()]">가입하기</button>
            </div>
        </section>
        <input type="hidden" id="birthDate" value="" />
        <input type="hidden" id="encryptString" value="" />
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import Service from '@/components/modal/SignUp/Service.vue';
import LocationBased from '@/components/modal/SignUp/LocationBased.vue';
import Privacy from '@/components/modal/SignUp/Privacy.vue';
import $ from 'jquery';
import checkboxAgreeAll from '../../components/checkbox/checkboxAgreeAll.vue';
import checkboxService from '../../components/checkbox/checkboxService.vue';
import checkboxLocationBased from '../../components/checkbox/checkboxLocationBased.vue';
import checkboxPrivacy from '../../components/checkbox/checkboxPrivacy.vue';
import checkboxMaketing from '../../components/checkbox/checkboxMaketing.vue';
import axios from 'axios';
import router from '@/router';
import vClickOutside from 'v-click-outside';
import { getUserCheck } from '@/service/api/niceApi';
import { signUp } from '@/service/api/userApi';
export default defineComponent({
  data () {
    return {
      isCheckNice: false,
      ShowService: false,
      ShowLocationInfo: false,
      ShowLocationBased: false,
      ShowPrivacy: false,
      ShowMarketing: false,
      email: '',
      email1: '',
      email2: '',
      password: '',
      password2: '',
      name: '',
      gender: '',
      mobileNo: '',
      birthDate: '',
      service: false,
      locationInfo: false,
      locationBased: false,
      privacy: false,
      marketing: false,
      agreeAll: false,
      emailCheck: false,
      checkPassEqual: null,
      checkPassword: true,
      authCheck: false,
      tokenVersionId: '',
      encData: '',
      integrityValue: '',
      selectBoxCheck: false,
      emailOtion: '직접입력',
      recommender: '',
      disabledSignUp: false,
      showAutoComplte: false,
      emailRegex: new RegExp('[0-9a-zA-Z_-]([-_.]?[0-9a-zA-Z_-])+@'),
      emailList: ['naver.com', 'gmail.com', 'hanmail.net', 'daum.net', 'kakao.com', 'nate.com', 'yahoo.com'],
      emailValidate: true,
      errorText: '',
      encryptString: '',
      passwordValidate: {
        engValidate: false,
        numValidate: false,
        specialValidate: false,
        lengthValidate: false,
        sameValidate: false
      }
    };
  },

  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    Service,
    // LocationInfo,
    LocationBased,
    Privacy,
    // Marketing,
    checkboxAgreeAll,
    checkboxPrivacy,
    checkboxService,
    checkboxMaketing,
    checkboxLocationBased
  },
  mounted () {
    this.setInputEvent();
  },
  methods: {
    setInputEvent () {
      const input = document.querySelector('#birthDate');
      const encryptString = document.querySelector('#encryptString');
      input.addEventListener('change', $event => {
        if (encryptString.value && $event.target.value) {
          this.isCheckNice = true;
          this.encryptString = encryptString.value;
          input.value = '';
          encryptString.value = '';
        }
      });
    },
    emailKeyPress () {
      if (this.emailRegex.test(this.email)) {
        this.showAutoComplte = true;

        setTimeout(() => {
          this.emailValidate = true;
          this.errorText = '';
        }, 1000);
      } else {
        this.showAutoComplte = false;
        setTimeout(() => {
          this.emailValidate = false;
          this.errorText = '이메일 형식이 올바르지 않습니다.';
        }, 1000);
      }
    },
    selectAutoComplete (index) {
      const autocomplete = document.querySelector('#autocomplete');
      if (autocomplete && index > -1) {
        autocomplete.childNodes[index].focus();
        autocomplete.childNodes[index].classList.add('focus');
      } else if (index < 0) {
        const email = document.querySelector('#email');
        email.focus();
      }
    },
    settingEmail (val) {
      this.email = val.textContent.trim();
      const email = document.querySelector('#email');
      email.focus();
      this.showAutoComplte = false;
    },
    makeFormData (tokenVersionId, encData, integrityValue) {
      const form = document.createElement('form');

      form.target = 'popup';
      form.method = 'Post';
      form.setAttribute('action', process.env.VUE_APP_NICE_URL);

      const inputService = document.createElement('input');
      inputService.setAttribute('type', 'hidden');
      inputService.setAttribute('value', 'service');
      inputService.setAttribute('id', 'm');
      inputService.setAttribute('name', 'm');

      form.appendChild(inputService);

      const inputToken = document.createElement('input');
      inputToken.setAttribute('type', 'hidden');
      inputToken.setAttribute('value', tokenVersionId);
      inputToken.setAttribute('id', 'token_version_id');
      inputToken.setAttribute('name', 'token_version_id');

      form.appendChild(inputToken);

      const inputEncData = document.createElement('input');
      inputEncData.setAttribute('type', 'hidden');
      inputEncData.setAttribute('value', encData);
      inputEncData.setAttribute('id', 'enc_data');
      inputEncData.setAttribute('name', 'enc_data');

      form.appendChild(inputEncData);

      const inputIv = document.createElement('input');
      inputIv.setAttribute('type', 'hidden');
      inputIv.setAttribute('value', integrityValue);
      inputIv.setAttribute('id', 'integrity_value');
      inputIv.setAttribute('name', 'integrity_value');

      form.appendChild(inputIv);

      document.body.appendChild(form);

      form.submit();

      document.body.removeChild(form);
    },
    async clickEvent () {
      window.open('', 'popup', 'width=545, height=875, toolbar=yes, location=no');
      getUserCheck()
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const { tokenVersionId, encData, integrityValue } = result;
            this.makeFormData(tokenVersionId, encData, integrityValue);
          }
        })
        .catch(error => {
          console.error('getUserCheck error  : ', error);
        });
    },
    checkAuth2 () {
      this.authCheck = this.isCheckNice;
    },
    signUp () {
      // 회원가입
      this.disabledSignUp = true;
      const email = this.email;
      const password = btoa(this.password);
      const recommender = this.recommender;
      const encryptString = this.encryptString;
      let marketing = '';

      this.marketing ? (marketing = 'Y') : (marketing = 'N');
      if (this.emailRegex.test(this.email)) {
        // 이메일 정상 기입
        if (this.emailCheck) {
          // 중복체크값 true
          if (this.isValidatePassword()) {
            // 비밀번호 유효성 체크
            if (this.password === this.password2) {
              // 입력한 비밀번호 2개가 일치
              if (this.isCheckNice) {
                // 본인인증 완료
                if (this.service) {
                  // 사이트 이용약관에 동의
                  if (this.privacy) {
                    // 개인정보 처리방침에 동의
                    // if (this.locationInfo) { //위치정보사업 이용약관에 동의
                    if (this.locationBased) {
                      // 위치기반서비스 이용약관에 동의
                      signUp(encryptString, email, password, marketing, recommender)
                        .then(res => {
                          if (res.data.resultCd === '0000') {
                            this.disabledSignUp = false;
                            this.$gatag.event('sign_up', {
                              method: 'email'
                            });
                            router.push({ name: 'SignUpFinished', params: { email: email } });
                          }
                        })
                        .catch(err => {
                          this.disabledSignUp = false;
                          console.log(err);
                        });
                    } else {
                      // 위치기반서비스 이용약관에 비동의
                      alert('위치기반서비스 이용약관에 동의해주세요.');
                      this.disabledSignUp = false;
                    }
                    /* } else { //위치정보사업 이용약관에 비동의
                      alert('위치정보사업 이용약관에 동의해주세요.');
                      this.disabledSignUp = false;
                    } */
                  } else {
                    // 개인정보 처리방침에 비동의
                    alert('개인정보 처리방침에 동의해주세요.');
                    this.disabledSignUp = false;
                  }
                } else {
                  // 사이트 이용약관에 비동의
                  alert('이용약관에 동의해주세요.');
                  this.disabledSignUp = false;
                }
              } else {
                alert('본인 인증을 진행해 주세요.');
                this.disabledSignUp = false;
              }
            } else {
              // 입력한 비밀번호 2개가 불일치
              alert('비밀번호를 동일하게 입력해주세요.');
              this.disabledSignUp = false;
              this.password = '';
              this.password2 = '';
              document.getElementById('password').focus();
            }
          } else {
            this.disabledSignUp = false;
            this.passwordCheck();
            document.getElementById('password').focus();
          }
        } else {
          alert('이메일 중복확인을 진행해 주세요.');
          this.disabledSignUp = false;
        }
      } else {
        alert('이메일을 바르게 입력해 주세요.');
        this.disabledSignUp = false;
        this.emailCheck = false;
        this.email1 = '';
        this.email2 = '';
        document.getElementById('email').focus();
      }
    },
    passwordCheck () {
      // 비밀번호 유효성
      const engPattern = /[a-zA-Z]/; // 영어;
      const numPattern = /[0-9]/; // 숫자
      // eslint-disable-next-line no-useless-escape
      const specialPattern = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g; // 특수문자

      if (engPattern.test(this.password)) {
        this.passwordValidate.engValidate = true;
      } else {
        this.passwordValidate.engValidate = false;
      }

      if (numPattern.test(this.password)) {
        this.passwordValidate.numValidate = true;
      } else {
        this.passwordValidate.numValidate = false;
      }

      if (specialPattern.test(this.password)) {
        this.passwordValidate.specialValidate = true;
      } else {
        this.passwordValidate.specialValidate = false;
      }

      if (this.password.length >= 8 && this.password.length <= 20) {
        this.passwordValidate.lengthValidate = true;
      } else {
        this.passwordValidate.lengthValidate = false;
      }
    },
    passEqualCheck () {
      // 비밀번호 2개 일치여부 확인
      this.password === this.password2
        ? (this.passwordValidate.sameValidate = true)
        : (this.passwordValidate.sameValidate = false);
      if (this.password2 === '') {
        this.passwordValidate.sameValidate = false;
      }
    },
    isValidatePassword () {
      // Object.keys(this.passwordValidate).filter(item=>this.passwordValidate[item] === false )
      const list = Object.keys(this.passwordValidate).filter(item => this.passwordValidate[item] === false);

      if (list.length > 0) {
        return false;
      }

      return true;
    },
    checkEmail () {
      // 이메일 유효성 체크
      const email = this.email;
      const regEmail = /^[0-9a-zA-Z_-]([-_.]?[0-9a-zA-Z_-])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

      const comeFrom = 'email';
      if (regEmail.test(email) === true) {
        axios
          .post('/api/user/checkEmailDup', { email, comeFrom })
          .then(res => {
            if (res.data.resultCd === '0000') {
              this.emailCheck = res.data.result;
              if (this.emailCheck) {
                alert('사용 가능한 이메일 주소입니다.');
              } else {
                this.emailValidate = false;
                this.errorText = '이미 등록된 이메일 주소입니다.';
                this.email1 = '';
                this.email2 = '';
                document.getElementById('email').focus();
              }
            }
          })
          .catch(err => {
            if (err.response.data.resultMsg === '탈퇴 후 14일이 지나지 않은 이메일입니다.') {
              alert('탈퇴 후 14일이 지나지 않아 재가입이 불가합니다.  \n다른 메일로 가입 부탁드립니다.');
              this.email1 = '';
              this.email2 = '';
              document.getElementById('email').focus();
            }
          });
      } else {
        alert('이메일을 바르게 입력해 주세요!');
        this.email1 = '';
        this.email2 = '';
        document.getElementById('email').focus();
      }
    },
    agreeAll1 () {
      // 약관 전체
      if (this.agreeAll) {
        this.service = true;
        // this.locationInfo = true;
        this.locationBased = true;
        this.privacy = true;
        this.marketing = true;
      } else {
        this.service = false;
        // this.locationInfo = false;
        this.locationBased = false;
        this.privacy = false;
        this.marketing = false;
      }
    },
    agreeService () {
      // 서비스 이용약관
      this.service === true && this.locationBased === true && this.privacy === true && this.marketing === true
        ? (this.agreeAll = true)
        : (this.agreeAll = false);
    },
    /* agreeLocationInfo () { //위치정보사업 이용약관
      this.service === true && this.locationInfo === true && this.locationBased === true && this.privacy === true && this.marketing === true ? this.agreeAll = true : this.agreeAll = false;
    }, */
    agreeLocationBased () {
      // 위치기반서비스 이용약관
      this.service === true && this.locationBased === true && this.privacy === true && this.marketing === true
        ? (this.agreeAll = true)
        : (this.agreeAll = false);
    },
    agreePrivacy () {
      // 개인정보 처리방침
      this.service === true && this.locationBased === true && this.privacy === true && this.marketing === true
        ? (this.agreeAll = true)
        : (this.agreeAll = false);
    },
    agreeMarketing () {
      // 마케팅 수신
      this.service === true && this.locationBased === true && this.privacy === true && this.marketing === true
        ? (this.agreeAll = true)
        : (this.agreeAll = false);
    },
    clickSelectBox () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach(opt => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          // console.log(lb.parentNode.classList);
          optionItems.forEach(opt => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        if (item.textContent !== '직접입력') {
          this.email2 = item.textContent;
        } else {
          this.email2 = '';
        }
        this.emailOtion = item.textContent;
        // label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },
    requestEmail () {
      $(document)
        .off('click')
        .on('click', '.email_input_btn', function () {
          $(this).text('재요청');
        });
    },
    checkAgree () {
      $(document)
        .off('click')
        .on('click', '.agree_box', function () {
          var $this = $(this);
          $this.find('>img').attr('src', function (index, attr) {
            if (attr.match('_off')) {
              return attr.replace('_off.png', '_active.png');
            } else {
              return attr.replace('_active.png', '_off.png');
            }
          });

          var $check = $('.agree_txt');
          $check.find('img').attr('src', function (index, attr) {
            if (attr.match('_of')) {
              return attr.replace('_of.png', '_on.png');
            } else {
              return attr.replace('_on.png', '_of.png');
            }
          });
        });
    },
    backspace () {
      // this.$router.push('/');
      history.back();
    }
  },
  computed: {
    getEmailList () {
      const splitArr = this.email.split('@');
      if (splitArr[splitArr.length - 1]) {
        return this.emailList.filter(item => item.startsWith(splitArr[splitArr.length - 1]));
      }

      return this.emailList;
    }
  }
});
</script>

<style scoped>
.disabled {
  pointer-events: none;
}

.email_input {
  margin-bottom: 42px;
}

button.join_certification {
  border: 1px solid rgba(255, 255, 255, 0.44);
  box-sizing: border-box;
  width: 100%;
  padding: 12px 0;
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  border-radius: 8px;
  text-align: center;
  margin: 32px 0px 32px;
  line-height: 1.2;
}

button.join_certification.check {
  display: flex;
  justify-content: center;
  align-items: center;
}

.done_img {
  width: 13px;
  height: 10px;
  margin-left: 7px;
}

.email_input_error {
  margin-bottom: 0px;
}

.email_input_box {
  width: 82%;
  position: relative;
}

.autocomplete {
  position: absolute;
  top: 57px;
  left: 0;
  width: 99%;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.6);
  background-color: var(--black141414);
  z-index: 99;
  padding: 0;
}

.autocomplete_item {
  color: var(--whitefff);
  font-size: 15px;
  line-height: 22px;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.16); */
  padding: 14px 16px 0 16px;
  cursor: pointer;
}

.autocomplete_item:hover {
  background: rgba(255, 255, 255, 0.06);
}

.border_line {
  height: 1px;
  width: 100%;
  margin-top: 16px;
  background-color: rgba(255, 255, 255, 0.16);
}

.autocomplete_item:last-child {
  padding-bottom: 16px;
}

.error_text {
  color: var(--FF4949) !important;
  font-size: 14px;
  margin: 0;
  text-decoration: none;
  margin-top: 0;
  text-align: left;
  padding: 10px 0;
  margin-bottom: 0px;
}

.password_vaildate_wrap {
  display: flex;
}

.password_vaildate_wrap.ml {
  margin-left: 4px;
}

.password_vaildate_wrap .validate_text {
  color: rgba(255, 255, 255, 0.6);
}

.validate_text.check {
  color: var(--secondary);
}

.password_input1 {
  margin-bottom: 0px;
}

@media all and (max-width: 500px) {
  .error_text {
    font-size: 12px;
    padding: 6px 0;
    display: inline-block;
    height: 32px;
  }

  .email_input_box {
    width: 77%;
  }

  .email_input {
    display: flex;
    margin-bottom: 32px;
  }

  .email_input_error {
    margin-bottom: 0;
  }

  .email_input .email_input_btn {
    margin-top: 0;
    width: 23%;
  }

  .password_input1 {
    margin-bottom: 0;
  }

  .in_pw {
    padding: 6px 0;
  }

  button.join_certification {
    font-size: 15px;
  }

  .password_vaildate_wrap .validate_text {
    font-size: 12px;
    line-height: 1.5;
  }

  .login_main_box button {
    line-height: 1;
  }

  .email_input .email_input_btn {
    height: 48px;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
